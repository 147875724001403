import React from "react"
import Layout from "@app-components/layout/"
import Background from "@app-components/utils/background"
import Form from "@page-components/contact/form/"
import { css } from "@emotion/core"

const Contact = () => (
  <Layout page="contact">
    <div className="white center bg-cover px2 md-px3 pb4" css={css`
      background: radial-gradient(circle, #6B6B6B 0%, #505050 0%, #000 100%);
      padding-top: 12rem;
      padding-bottom: 14rem;
    `}>
      <h1>Request Access</h1>
    </div>
    <Background tagName="section" className="bg-cover bg-no-repeat bg-center" type="gray-right-rounded">
      <Form />
    </Background>
  </Layout>
)

export default Contact