import React from "react"
import validateEmail from "@helpers/validate_email"
import Icon from "@app-components/utils/icon"
import Arrow from "@app-components/utils/arrow"

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      phone_number: "",
      email: "",
      message: "",
      status: "IDLE",
      errors: {
        email: false,
        message: false
      }
    }
    this.checkDetails = this.checkDetails.bind(this)
  }
  checkDetails(e) {
    e.preventDefault()
    e.stopPropagation()
    const errors = {
      email: !validateEmail(this.state.email),
      message: !this.state.message.length
    }
    if (Object.keys(errors).filter(key => errors[key]).length > 0) {
      this.setState({ errors })
    } else {
      this.setState(
        {
          status: "PENDING"
        },
        () => {
          const url = "https://api.cinesend.com/api/website/trailers"
          fetch(
            `${url}?email=${this.state.email}&message=${this.state.message}&phone_number=${
              this.state.phone_number
            }&name=${this.state.name}`,
            {
              method: "POST"
            }
          )
            .then(res => res.json())
            .then(() => this.setState({ status: "COMPLETE" }))
            .catch(() => this.setState({ status: "ERROR" }))
        }
      )
    }
  }
  render() {
    return (
      <section>
        <div className="px2">
          <form
            style={{
              boxShadow: "0 4px 10px 0 #C7CDCF",
              transform: "translateY(-10rem)",
              borderRadius: "8px",
              maxWidth: "600px"
            }}
            className="bg-white col-12 mx-auto p3">
            {["IDLE", "PENDING"].includes(this.state.status) ? (
              <div>
                <h3 className="mb1">Send us a message</h3>
                <p style={{ fontSize: "14px" }} className="mb2">
                  Connect with our exhibitor relations team to set up an account.
                </p>
                {["name", "email", "phone_number"].map(input => (
                  <div key={input} className="mb2">
                    <label className="capitalize col-12 block" htmlFor={input}>
                      {input.split("_").join(" ")}
                    </label>
                    <input
                      value={this.state[input]}
                      className={`col-12 sm-col-8 block ${this.state.errors[input] ? "error" : ""}`}
                      onChange={e => this.setState({ [input]: e.target.value })}
                    />
                    {this.state.errors[input] && (
                      <small className="block red mt1">Invalid {input.split("_").join(" ")}</small>
                    )}
                  </div>
                ))}
                <div className="mb2">
                  <label htmlFor="message">Which cinema(s) do you represent?</label>
                  <textarea
                    value={this.state.message}
                    className={`col-12 ${this.state.errors.message ? "error" : null}`}
                    onChange={e => this.setState({ message: e.target.value })}
                  />
                  {this.state.errors.message && <small className="block red mt1">A message is required</small>}
                </div>
                <div className="mt3 right-align">
                  <button
                    type="submit"
                    disabled={this.state.status === "PENDING"}
                    className={this.state.status === "PENDING" ? "pending" : ""}
                    onClick={this.checkDetails}>
                    Send
                  </button>
                </div>
              </div>
            ) : this.state.status === "COMPLETE" ? (
              <div className="py2 center">
                <Icon size="64" className="inline-block" icon="check-white" />
                <h3 className="mt1 center">Thank You!</h3>
                <p className="large-p-tag mt1 mb3">
                  We’ll get back to you as soon as possible
                  <br />
                  at <b>{this.state.email}</b>{" "}
                </p>
                <p className="large-p-tag">
                  Have another question for us?&nbsp;
                  <a
                    className="pointer inline-flex items-center"
                    role="presentation"
                    onClick={() => this.setState({ status: "IDLE" })}>
                    <strong className="red">Send us another message</strong>
                    <Arrow color="red" className="ml1" />
                  </a>
                </p>
              </div>
            ) : (
              <div>An error occurred. Please try again.</div>
            )}
          </form>
        </div>
      </section>
    )
  }
}

export default Form
